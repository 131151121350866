var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_container"},[_c('div',{staticClass:"sub_wrapper"},[_c('h2',{staticClass:"sub-title1"},[_vm._v(_vm._s(_vm.$t('menu3-detail')))]),_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"sub_table el-table-m"},[_c('dl',{staticClass:"display_detail"},[_c('dt',[_vm._v(_vm._s(_vm.$t('columnTxHash')))]),_c('dd',[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_detail_txt"},[_vm._v(_vm._s(_vm.txData.tx_id))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.tx_id);
                          _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}},[_vm._v(" copy ")])])]),_c('dt',[_vm._v(_vm._s(_vm.$t('columnTime')))]),_c('dd',[_vm._v(_vm._s(_vm.txData.tx_dt)+" UTC")]),_c('dt',[_vm._v(_vm._s(_vm.$t('columnResult')))]),_c('dd',[_vm._v(_vm._s(_vm.txData.tx_status))]),_c('dt',[_vm._v(_vm._s(_vm.$t('columnBlockNo')))]),_c('dd',[_vm._v(_vm._s(_vm.txData.block_nm))]),_c('dt',[_vm._v(_vm._s(_vm.$t('columnTxFee')))]),_c('dd',[_vm._v(_vm._s(_vm.txData.gas_fee)+" "+_vm._s(_vm.txData.fee_symbol))]),_c('dt',[_vm._v(_vm._s(_vm.$t('columnTxType')))]),_c('dd',[_vm._v(_vm._s(_vm.txData.tx_type))]),(_vm.txData.from_addr !== null)?_c('dt',[_vm._v(_vm._s(_vm.$t('columnFrom')))]):_vm._e(),(_vm.txData.from_addr !== null)?_c('dd',[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_detail_txt"},[_vm._v(_vm._s(_vm.txData.from_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.from_addr);
                              _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])]):_vm._e(),(_vm.txData.to_addr !== null )?_c('dt',[_vm._v(_vm._s(_vm.$t('columnTo')))]):_vm._e(),(_vm.txData.to_addr !== null )?_c('dd',[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_detail_txt"},[_vm._v(_vm._s(_vm.txData.to_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.to_addr);
                              _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])]):_vm._e(),(_vm.txData.validator_addr !== null)?_c('dt',[_vm._v(" "+_vm._s(_vm.$t('columnValidatorAddress'))+" ")]):_vm._e(),(_vm.txData.validator_addr !== null)?_c('dd',[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_detail_txt"},[_vm._v(_vm._s(_vm.txData.validator_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.validator_addr);
                              _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])]):_vm._e(),(_vm.txData.delegator_addr !== null)?_c('dt',[_vm._v(" "+_vm._s(_vm.$t('columnDelegatorAddress'))+" ")]):_vm._e(),(_vm.txData.delegator_addr !== null)?_c('dd',[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_detail_txt"},[_vm._v(_vm._s(_vm.txData.delegator_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.delegator_addr);
                                _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])]):_vm._e(),(_vm.txData.validator_dst_addr !== null)?_c('dt',[_vm._v(" "+_vm._s(_vm.$t('columnValidatorDstAddress'))+" ")]):_vm._e(),(_vm.txData.validator_dst_addr !== null)?_c('dd',[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_detail_txt"},[_vm._v(_vm._s(_vm.txData.validator_dst_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.validator_dst_addr);
                            _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])]):_vm._e(),(_vm.txData.validator_src_addr !== null)?_c('dt',[_vm._v(" "+_vm._s(_vm.$t('columnValidatorSrcAddress'))+" ")]):_vm._e(),(_vm.txData.validator_src_addr !== null)?_c('dd',[_c('div',{staticClass:"d-flex align-tiems-center"},[_c('span',{staticClass:"block_hash_detail_txt"},[_vm._v(_vm._s(_vm.txData.validator_src_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.validator_src_addr);
                            _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])]):_vm._e(),_c('dt',[_vm._v(_vm._s(_vm.$t('columnAmount')))]),_c('dd',[(_vm.txData.tx_type ==='withdraw_delegator_reward'
                                    && _vm.txData.amount === 0 )?_c('span',[_vm._v("more")]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(_vm.txData.amount))}}),_vm._v(" "+_vm._s(_vm.txData.symbol))])])]),_c('el-descriptions',{staticClass:"margin-top el-table",attrs:{"column":1,"border":""}},[_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnTxHash'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_txt"},[_vm._v(_vm._s(_vm.txData.tx_id))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.tx_id);
                          _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])],2),_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnTime'))+" ")]),_vm._v(" "+_vm._s(_vm.txData.tx_dt)+" UTC ")],2),_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnResult'))+" ")]),_vm._v(" "+_vm._s(_vm.txData.tx_status)+" ")],2),_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnBlockNo'))+" ")]),_vm._v(" #"+_vm._s(_vm.txData.block_nm)+" ")],2),_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnTxFee'))+" ")]),_vm._v(" "+_vm._s(_vm.txData.gas_fee)+" "+_vm._s(_vm.txData.fee_symbol)+" ")],2),_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnTxType'))+" ")]),_vm._v(" "+_vm._s(_vm.$t(_vm.txData.tx_type))+" ")],2),(_vm.txData.from_addr !== null)?_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnFrom'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_txt"},[_vm._v(_vm._s(_vm.txData.from_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.from_addr);
                        _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])],2):_vm._e(),(_vm.txData.to_addr !== null )?_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnTo'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_txt"},[_vm._v(_vm._s(_vm.txData.to_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.to_addr);
                        _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])],2):_vm._e(),(_vm.txData.validator_addr !== null)?_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnValidatorAddress'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_txt"},[_vm._v(_vm._s(_vm.txData.validator_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.validator_addr);
                              _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])],2):_vm._e(),(_vm.txData.delegator_addr !== null)?_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnDelegatorAddress'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_txt"},[_vm._v(_vm._s(_vm.txData.delegator_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.delegator_addr);
                          _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])],2):_vm._e(),(_vm.txData.validator_dst_addr !== null)?_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnValidatorDstAddress'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_txt"},[_vm._v(_vm._s(_vm.txData.validator_dst_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.validator_dst_addr);
                              _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])],2):_vm._e(),(_vm.txData.validator_src_addr !== null)?_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnValidatorSrcAddress'))+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_txt"},[_vm._v(_vm._s(_vm.txData.validator_src_addr))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(_vm.txData.validator_src_addr);
                              _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])],2):_vm._e(),_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('columnAmount'))+" ")]),(_vm.txData.tx_type ==='withdraw_delegator_reward'
                                    && _vm.txData.amount === 0 )?_c('span',[_vm._v("more")]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(_vm.txData.amount))}}),_vm._v(" "+_vm._s(_vm.txData.symbol)+" ")],2)],1)],1),(_vm.txData.tx_type === 'withdraw_delegator_reward')?_c('h2',{staticClass:"sub-title2"},[_vm._v(" "+_vm._s(_vm.$t('txRewards'))+" ")]):_vm._e(),(_vm.txData.tx_type === 'withdraw_delegator_reward')?_c('el-card',{staticClass:"box-card get_reward"},[_vm._l((_vm.rewards),function(reward,index){return [_c('dl',{key:'dl'+index,staticClass:"fl_wrap"},[_c('dt',{key:'dt01'+index},[_vm._v(_vm._s(_vm.$t('columnDelegatorAddress'))+" ")]),_c('dd',{key:'dd01'+index},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_txt"},[_vm._v(_vm._s(reward.delegator_address))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(reward.delegator_address);
                                  _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])]),_c('dt',{key:'dt02'+index},[_vm._v(_vm._s(_vm.$t('columnValidatorAddress')))]),_c('dd',{key:'dd02'+index},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"block_hash_txtmt10"},[_vm._v(_vm._s(reward.validator_address))]),_c('span',{staticClass:"ico_copy",on:{"click":function($event){_vm.$func.copy_to_clipboard(reward.validator_address);
                                _vm.$message({message: _vm.$t('msgCopy'), type: 'success' });}}})])]),_c('dt',{key:'dt03'+index},[_vm._v(_vm._s(_vm.$t('columnAmount')))]),_c('dd',{key:'dd03'+index},[_c('span',{staticClass:"block_hash_txt"},[_vm._v(_vm._s(reward.amount)+" "+_vm._s(_vm.txData.symbol))])])])]})],2):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
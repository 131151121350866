<template>
    <div class="sub_container">
      <div class="sub_wrapper">
        <h2 class="sub-title1">{{ $t('menu3-detail') }}</h2>
            <el-card class="box-card">
                <div class="sub_table el-table-m">
                  <dl class="display_detail">
                    <dt>{{ $t('columnTxHash') }}</dt>
                    <dd>
                      <div class="d-flex align-items-center">
                        <span class="block_hash_detail_txt">{{ txData.tx_id }}</span>
                        <span class="ico_copy"
                          @click="$func.copy_to_clipboard(txData.tx_id);
                          $message({message: $t('msgCopy'), type: 'success' });">
                          copy
                        </span>
                      </div>
                    </dd>
                    <dt>{{ $t('columnTime') }}</dt>
                    <dd>{{ txData.tx_dt }} UTC</dd>
                    <dt>{{ $t('columnResult') }}</dt>
                    <dd>{{ txData.tx_status }}</dd>
                    <dt>{{ $t('columnBlockNo') }}</dt>
                    <dd>{{ txData.block_nm }}</dd>
                    <dt>{{ $t('columnTxFee') }}</dt>
                    <dd>{{ txData.gas_fee }} {{ txData.fee_symbol }}</dd>
                    <dt>{{ $t('columnTxType') }}</dt>
                    <dd>{{ txData.tx_type }}</dd>
                    <dt v-if="txData.from_addr !== null">{{ $t('columnFrom') }}</dt>
                    <dd v-if="txData.from_addr !== null">
                      <div class="d-flex align-items-center">
                        <span class="block_hash_detail_txt">{{txData.from_addr}}</span>
                        <span class="ico_copy"
                            @click="$func.copy_to_clipboard(txData.from_addr);
                              $message({message: $t('msgCopy'), type: 'success' });">
                        </span>
                      </div>
                    </dd>
                    <dt v-if="txData.to_addr !== null ">{{ $t('columnTo') }}</dt>
                    <dd v-if="txData.to_addr !== null ">
                      <div class="d-flex align-items-center">
                        <span class="block_hash_detail_txt">{{txData.to_addr}}</span>
                        <span class="ico_copy"
                            @click="$func.copy_to_clipboard(txData.to_addr);
                              $message({message: $t('msgCopy'), type: 'success' });">
                        </span>
                      </div>
                    </dd>
                    <dt v-if="txData.validator_addr !== null">
                      {{ $t('columnValidatorAddress') }}
                    </dt>
                    <dd v-if="txData.validator_addr !== null">
                      <div class="d-flex align-items-center">
                        <span class="block_hash_detail_txt">{{txData.validator_addr}}</span>
                        <span class="ico_copy"
                            @click="$func.copy_to_clipboard(txData.validator_addr);
                              $message({message: $t('msgCopy'), type: 'success' });">
                        </span>
                      </div>
                    </dd>
                    <dt v-if="txData.delegator_addr !== null">
                      {{ $t('columnDelegatorAddress') }}
                    </dt>
                    <dd v-if="txData.delegator_addr !== null">
                      <div class="d-flex align-items-center">
                        <span class="block_hash_detail_txt">{{txData.delegator_addr}}</span>
                        <span class="ico_copy"
                              @click="$func.copy_to_clipboard(txData.delegator_addr);
                                $message({message: $t('msgCopy'), type: 'success' });">
                        </span>
                      </div>
                    </dd>
                    <dt v-if="txData.validator_dst_addr !== null">
                      {{ $t('columnValidatorDstAddress') }}
                    </dt>
                    <dd v-if="txData.validator_dst_addr !== null">
                      <div class="d-flex align-items-center">
                        <span class="block_hash_detail_txt">{{txData.validator_dst_addr}}</span>
                        <span class="ico_copy"
                            @click="$func.copy_to_clipboard(txData.validator_dst_addr);
                            $message({message: $t('msgCopy'), type: 'success' });">
                        </span>
                      </div>
                    </dd>
                    <dt v-if="txData.validator_src_addr !== null">
                      {{ $t('columnValidatorSrcAddress') }}
                    </dt>
                    <dd v-if="txData.validator_src_addr !== null">
                      <div class="d-flex align-tiems-center">
                        <span class="block_hash_detail_txt">{{txData.validator_src_addr}}</span>
                        <span class="ico_copy"
                            @click="$func.copy_to_clipboard(txData.validator_src_addr);
                            $message({message: $t('msgCopy'), type: 'success' });">
                        </span>
                      </div>
                    </dd>
                    <dt>{{ $t('columnAmount') }}</dt>
                    <dd><span v-if="txData.tx_type ==='withdraw_delegator_reward'
                                    && txData.amount === 0 ">more</span>
                    <span v-else v-html="$options.filters.currency(txData.amount)"></span>
                    {{txData.symbol}}</dd>
                  </dl>
                </div>
                <el-descriptions class="margin-top el-table" :column="1"  border>
                    <el-descriptions-item>
                    <template slot="label">
                        {{ $t('columnTxHash') }}
                    </template>
                    <div class="d-flex align-items-center">
                      <span class="block_hash_txt">{{txData.tx_id}}</span>
                      <span class="ico_copy"
                          @click="$func.copy_to_clipboard(txData.tx_id);
                          $message({message: $t('msgCopy'), type: 'success' });">
                      </span>
                    </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('columnTime')}}
                    </template>
                      {{txData.tx_dt}} UTC
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('columnResult') }}
                    </template>
                      {{txData.tx_status}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('columnBlockNo') }}
                    </template>
                      #{{txData.block_nm}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('columnTxFee') }}
                    </template>
                      {{txData.gas_fee}} {{txData.fee_symbol}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('columnTxType') }}
                    </template>
                      {{$t(txData.tx_type)}}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="txData.from_addr !== null">
                    <template slot="label">
                      {{ $t('columnFrom') }}
                    </template>
                    <div class="d-flex align-items-center">
                      <span class="block_hash_txt">{{txData.from_addr}}</span>
                      <span class="ico_copy"
                        @click="$func.copy_to_clipboard(txData.from_addr);
                        $message({message: $t('msgCopy'), type: 'success' });">
                      </span>
                    </div>
                    </el-descriptions-item>
                    <el-descriptions-item v-if="txData.to_addr !== null ">
                    <template slot="label">
                      {{ $t('columnTo') }}
                    </template>
                    <div class="d-flex align-items-center">
                      <span class="block_hash_txt">{{txData.to_addr}}</span>
                      <span class="ico_copy"
                        @click="$func.copy_to_clipboard(txData.to_addr);
                        $message({message: $t('msgCopy'), type: 'success' });">
                      </span>
                    </div>
                    </el-descriptions-item>
                    <el-descriptions-item v-if="txData.validator_addr !== null">
                    <template slot="label">
                      {{ $t('columnValidatorAddress') }}
                    </template>
                    <div class="d-flex align-items-center">
                      <span class="block_hash_txt">{{txData.validator_addr}}</span>
                      <span class="ico_copy"
                            @click="$func.copy_to_clipboard(txData.validator_addr);
                              $message({message: $t('msgCopy'), type: 'success' });">
                      </span>
                    </div>
                    </el-descriptions-item>
                    <el-descriptions-item v-if="txData.delegator_addr !== null">
                    <template slot="label">
                      {{ $t('columnDelegatorAddress') }}
                    </template>
                    <div class="d-flex align-items-center">
                      <span class="block_hash_txt">{{txData.delegator_addr}}</span>
                      <span class="ico_copy"
                          @click="$func.copy_to_clipboard(txData.delegator_addr);
                          $message({message: $t('msgCopy'), type: 'success' });">
                      </span>
                    </div>
                    </el-descriptions-item>
                    <el-descriptions-item v-if="txData.validator_dst_addr !== null">
                      <template slot="label">
                        {{ $t('columnValidatorDstAddress') }}
                      </template>
                      <div class="d-flex align-items-center">
                        <span class="block_hash_txt">{{txData.validator_dst_addr}}</span>
                        <span class="ico_copy"
                            @click="$func.copy_to_clipboard(txData.validator_dst_addr);
                              $message({message: $t('msgCopy'), type: 'success' });">
                        </span>
                      </div>
                    </el-descriptions-item>
                    <el-descriptions-item v-if="txData.validator_src_addr !== null">
                      <template slot="label">
                        {{ $t('columnValidatorSrcAddress') }}
                      </template>
                      <div class="d-flex align-items-center">
                        <span class="block_hash_txt">{{txData.validator_src_addr}}</span>
                        <span class="ico_copy"
                            @click="$func.copy_to_clipboard(txData.validator_src_addr);
                              $message({message: $t('msgCopy'), type: 'success' });">
                        </span>
                      </div>
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                      {{ $t('columnAmount') }}
                    </template>
                    <span v-if="txData.tx_type ==='withdraw_delegator_reward'
                                    && txData.amount === 0 ">more</span>
                    <span v-else v-html="$options.filters.currency(txData.amount)"></span>
                    {{txData.symbol}}
                    </el-descriptions-item>
                </el-descriptions>
             </el-card>

             <h2 class="sub-title2" v-if="txData.tx_type === 'withdraw_delegator_reward'">
              {{$t('txRewards')}}
             </h2>
             <el-card class="box-card get_reward"
             v-if="txData.tx_type === 'withdraw_delegator_reward'">
                <!-- S:reward List //-->
                  <template v-for="(reward, index) in rewards">
                    <dl :key="'dl'+index" class="fl_wrap">
                      <dt :key="'dt01'+index" >{{$t('columnDelegatorAddress')}}
                      </dt>
                      <dd :key="'dd01'+index">
                          <div class="d-flex align-items-center">
                            <span class="block_hash_txt">{{reward.delegator_address}}</span>
                            <span class="ico_copy"
                                @click="$func.copy_to_clipboard(reward.delegator_address);
                                  $message({message: $t('msgCopy'), type: 'success' });"></span>
                          </div>
                      </dd>
                      <dt :key="'dt02'+index" >{{$t('columnValidatorAddress')}}</dt>
                      <dd :key="'dd02'+index">
                        <div class="d-flex align-items-center">
                          <span class="block_hash_txtmt10">{{reward.validator_address}}</span>
                          <span class="ico_copy"
                              @click="$func.copy_to_clipboard(reward.validator_address);
                                $message({message: $t('msgCopy'), type: 'success' });"></span>
                        </div>
                      </dd>
                      <dt :key="'dt03'+index" >{{$t('columnAmount')}}</dt>
                      <dd :key="'dd03'+index">
                          <span class="block_hash_txt">{{reward.amount}}
                            {{txData.symbol}}</span> </dd>
                    </dl>
                  </template>
                <!-- E:reward List //-->
            </el-card>
      </div>
    </div>
</template>
<script>

export default {
  name: 'transactionDetail',
  components: {
  },
  data() {
    return {
      txData: {},
      txId: '',
      rewards: [],
    };
  },
  methods: {
    async getTransaction() {
      try {
        const { data } = await this.$axios.get(`/txs/${this.txId}`);
        if (data.ret_code === 200) {
          this.txData = data.data;
          if (data.data.tx_type === 'withdraw_delegator_reward') {
            this.rewards = data.data.rewards;
          }
        } else {
          this.$message.error(`${this.txId}: ${this.$t('411codeMessage')}`);
          this.$router.push({ name: 'transactions' });
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },

  created() {
    this.txId = this.$route.params.me;
    this.getTransaction();
  },
  mounted() {

  },
};
</script>
